import React from 'react';
import styled from 'styled-components';
import * as AppContext from '../../AppContext';
import * as AppActions from '../../AppActions';
import ProductPage from './index';
import queryParams from '../../utils/queryParams';

function ProductPreviewPage(props) {
  const [product, setProduct] = React.useState(null);
  const app = React.useContext(AppContext.Context);

  let _dataInUrl = queryParams(props.location.search);

  React.useEffect(() => {
    const _fetchData = async (id) => {
      AppActions.setLoading(true);
      try {
        let _products = await app.actions.getProducts();
        app.actions.setProducts(_products);
        let _product = await app.actions.getProductById(id);
        setProduct(_product);
      } catch (err) {
        //
      }
      AppActions.setLoading(false);
    };

    if (_dataInUrl.id) {
      _fetchData(_dataInUrl.id);
    }
  }, [_dataInUrl.id]);

  if (!product) {
    return null;
  }

  return (
    <ProductPage
      location={props.location}
      pageContext={{
        ...props.pageContext,
        product,
      }}
    />
  );
}

const Wrapper = styled.div`
  max-width: var(--content-max-width);
  padding: 0px var(--content-padding);
  margin: 0 auto;
  width: 100%;
  padding-top: 45px;
`;

export default ProductPreviewPage;
